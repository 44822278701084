import React, { useState } from "react";
import { connect, useSelector } from "react-redux";

import { UserForm, Table } from "@components/PracticeUser";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

import { getRoleInfo } from "@util/roleInfo";

function UserManagement({ practiceId, sessionPracticeId }) {
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <Wrapped
      title="Users"
      button={
        !roleInfo.admin_research_fellow && (
          <Button.Toggle
            closedText="Add Users"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        )
      }
    >
      {isOpen ? (
        <UserForm
          onSuccess={() => setOpen(false)}
          practiceId={practiceId || sessionPracticeId}
        />
      ) : (
        <Table practiceId={practiceId} />
      )}
    </Wrapped>
  );
}

UserManagement.defaultProps = {
  practiceId: null,
};

function mapStateToProps(state) {
  return {
    sessionPracticeId: state.user ? state.user.practice_id : null,
  };
}

export default connect(mapStateToProps)(UserManagement);

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { Wrapped } from "@components/Layouts";
import { BasicGrid, Card, Icon, Button, EmptyList } from "@components/shared";
import { DoctorPracticeUsers } from "@components/Doctor";
import { doctorVariety } from "./doctorOptions";

import { getRoleInfo } from "@util/roleInfo";

// see doctors in a practice
// see practices a doctor belongs to

export default function DoctorPractices({
  doctorId,
  practiceId,
  isPracticeUser,
}) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchData();
  }, [doctorId, practiceId]);

  function fetchData() {
    if (practiceId) fetchPracticeDoctors();
    if (doctorId) fetchDoctorPractices();
  }

  function fetchDoctorPractices() {
    setLoading(true);

    axios
      .get(`/doctors/${doctorId}/practices/`)
      .then(({ data }) => {
        setLoading(false);
        setItems(
          data.response.map((m) => ({
            name: m.practice_name,
            status: m.status,
            url: `/practices/${m.practice_id}`,
            id: m.id,
            practice_id: m.practice_id,
            occupational_variety: m.occupational_variety,
          }))
        );
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  function fetchPracticeDoctors() {
    axios
      .get(`/practices/${practiceId}/doctors/`)
      .then(({ data }) => {
        setLoading(false);
        setItems(
          data.response.map((m) => ({
            name: m.doctor_name,
            url: `/doctors/${m.doctor_id}`,
            id: m.id,
            doctor_id: m.doctor_id,
            practice_id: m.practice_id,
            status: m.status,
            occupational_variety: m.occupational_variety,
          }))
        );
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  if (!doctorId && !practiceId) return null;

  return (
    <Wrapped
      title={doctorId ? "Trusts" : "Consultant Firms"}
      button={
        !roleInfo.admin_research_fellow && (
          <Button.Toggle
            closedText="Add"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        )
      }
    >
      {!isOpen && (
        <React.Fragment>
          {!loading && items.length === 0 && (
            <EmptyList
              text={
                doctorId
                  ? "This consultant firm has no trusts yet"
                  : "This trust has no consultant firms yet"
              }
            />
          )}
          {items.length > 0 && (
            <BasicGrid columns={1}>
              {items
                .filter((f) => f.status === 1)
                .map((m) => (
                  <Item
                    key={m.id}
                    fetchData={fetchData}
                    id={m.id}
                    isPracticeUser={isPracticeUser}
                    roleInfo={roleInfo}
                    name={m.name}
                    occupationalVariety={m.occupational_variety}
                  />
                ))}
            </BasicGrid>
          )}
        </React.Fragment>
      )}
      {isOpen && (
        <AddForm
          doctorId={doctorId}
          practiceId={practiceId}
          disabledIds={items.map((m) =>
            doctorId
              ? m.status === 2
                ? ""
                : m.practice_id
              : m.status === 2
              ? ""
              : m.doctor_id
          )}
          onSuccess={() => {
            setOpen(false);
            fetchData();
          }}
          isPracticeUser={isPracticeUser}
        />
      )}
    </Wrapped>
  );
}

const RemoveButton = ({ joinId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      join_id: joinId,
    };

    axios
      .post(`/doctor-remove-from-practice/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Removed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button.Icon
      icon={<Icon.Remove size={26} color="var(--primaryColorDark)" />}
      onClick={onClick}
      loading={loading}
    />
  );
};

const AddForm = ({
  doctorId,
  disabledIds,
  practiceId,
  onSuccess,
  isPracticeUser,
}) => {
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [occupationalVarietyValue, setOccupationalVariety] = useState(null);

  useEffect(() => {
    if (!doctorId) return;
    fetchPractices();
  }, [doctorId]);

  useEffect(() => {
    if (!practiceId) return;
    fetchDoctors();
  }, [practiceId]);

  function fetchPractices() {
    setLoading(true);

    const req = {
      filtered: [],
      page: 0,
      page_size: 500,
      sorted: [],
      status: 1,
      value: null,
    };

    axios
      .post(`/retrieve-practices/`, req)
      .then(({ data }) => {
        setLoading(false);
        setOptions(
          data.response[0].data
            .map((d) => ({
              key: d.id,
              text: d.name,
              value: d.id,
              disabled: disabledIds.includes(d.id),
              description: disabledIds.includes(d.id) ? "Already added" : "",
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        );
      })
      .catch((err) => {
        setLoading(false);
        setOptions([]);
      });
  }

  function fetchDoctors() {
    setLoading(true);

    const req = {
      filtered: [],
      page: 0,
      page_size: 500,
      sorted: [],
      status: 1,
      value: null,
    };

    axios
      .post(`/retrieve-doctors/`, req)
      .then(({ data }) => {
        setLoading(false);
        setOptions(
          data.response[0].data
            .map((d) => ({
              key: d.id,
              text: d.full_name,
              value: d.id,
              disabled: disabledIds.includes(d.id),
              description: disabledIds.includes(d.id) ? "Already added" : "",
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        );
      })
      .catch((err) => {
        setLoading(false);
        setOptions([]);
      });
  }

  function onSubmit() {
    setSubmitting(true);

    const req = {
      doctor_id: doctorId || selected,
      practice_id: practiceId || selected,
      occupational_variety: occupationalVarietyValue,
    };

    axios
      .post(`/doctors/${doctorId || selected}/practices/`, req)
      .then(() => {
        setSubmitting(false);
        toast.success("Trust added!");
        onSuccess();
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err);
      });
  }

  return (
    <Form>
      <Form.Select
        onChange={(e, d) => setSelected(d.value)}
        placeholder={`Select a ${doctorId ? "trust" : "consultant firm"}`}
        value={selected}
        loading={loading}
        options={options}
        search
      />
      <Form.Select
        label="Role"
        required
        options={doctorVariety}
        placeholder="Select one..."
        value={occupationalVarietyValue}
        onChange={(e, d) => {
          setOccupationalVariety(d.value);
        }}
      />
      <Button.Basic
        primary
        onClick={onSubmit}
        loading={submitting}
        text={
          doctorId
            ? "Add trust to consultant firm"
            : "Add consultant firm to trust"
        }
        disabled={!selected || submitting || !occupationalVarietyValue}
      />
      {!isPracticeUser && (
        <div style={{ marginTop: "2em", fontWeight: "bold" }}>
          <Button.Link
            text="Need to create a consultant firm?"
            to={`/doctors`}
          />
        </div>
      )}
    </Form>
  );
};

const Item = ({
  id,
  name,
  fetchData,
  url,
  isPracticeUser,
  roleInfo,
  occupationalVariety,
}) => {
  const [occupationalVarietyValue, setOccupationalVariety] = useState(null);

  useEffect(() => {
    if (occupationalVariety) {
      setOccupationalVariety(occupationalVariety);
    }
  }, [occupationalVariety]);

  function onRoleChange(newVal) {
    setOccupationalVariety(newVal);

    axios
      .post(`/update-occupational-variety/`, {
        doctor_practice_id: id,
        occupational_variety: newVal,
      })
      .then(() => {
        true;
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Card.Wrapper key={id}>
      <Card.Meta title={name} />
      <div style={{ padding: "10px", backgroundColor: "floralwhite" }}>
        <Form>
          <Form.Select
            label="Role"
            required
            options={doctorVariety}
            placeholder="Select one..."
            value={occupationalVarietyValue}
            onChange={(e, d) => {
              onRoleChange(d.value);
            }}
          />
        </Form>
      </div>
      {!roleInfo.admin_research_fellow && (
        <Card.Actions>
          <div style={{ flexGrow: 1 }}>
            <RemoveButton joinId={id} onSuccess={fetchData} />
          </div>
          {!isPracticeUser && (
            <Button.Link text={<Icon.Eye size={26} />} to={url} />
          )}
        </Card.Actions>
      )}
    </Card.Wrapper>
  );
};

import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useSelector } from "react-redux";

import { Wrapped } from "@components/Layouts";
import { ProcedureCreate, ProcedureList } from "@components/Procedure";
import { Button } from "@components/shared";

import { getRoleInfo } from "@util/roleInfo";

export default function PatientProcedures({
  eyeApproval,
  isAdmin,
  patientId,
  onCreate,
}) {
  const [isOpen, setOpen] = useState(false);

  const searchQuery = queryString.parse(location.search);
  const practiceId = searchQuery.practiceId;

  useEffect(() => {
    if (practiceId) {
      setOpen(true);
    }
  }, [practiceId]);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <Wrapped
      title="Procedures"
      button={
        roleInfo.user_doctor ||
        roleInfo.user_proxy ||
        roleInfo.user_inst_admin ||
        roleInfo.user_originator ? (
          <Button.Toggle
            closedText="Add Patients"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        ) : null
      }
    >
      {isOpen ? (
        <ProcedureCreate
          isAdmin={isAdmin}
          patientId={patientId}
          practiceId={practiceId}
          eyeApproval={eyeApproval}
          onSuccess={(proId) => {
            setOpen(false);
            onCreate(proId);
          }}
        />
      ) : (
        <ProcedureList
          showPatientLink={false}
          isAdmin={isAdmin}
          patientId={patientId}
          retrievalData={{
            url: `/patients/${patientId}/retrieve-procedures/`,
            req: {
              patient_id: patientId,
            },
          }}
        />
      )}
    </Wrapped>
  );
}

PatientProcedures.defaultProps = {
  isAdmin: false,
};

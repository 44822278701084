import React, { createContext, useContext, useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { Modal } from "semantic-ui-react";
import styled from "styled-components";

import JoinPickers from "./JoinPickers";
import ViewPicker from "./ViewPicker";
import QueryBuilderForm from "./QueryBuilderForm";
import { Button, EmptyList } from "@components/shared";
import { QueryContainerForm } from "@components/QueryContainer";
import QueryBuilderOutput from "./QueryBuilderOutput";
import QuerySummary from "./QuerySummary";

import { formatQueryRequest, formatProcedureJoins } from "./helpers";

const initialState = {
  loading: false,
  activeView: "patient",
  patientValues: {
    age_min: "",
    age_max: "",
    gender: [],
    race: [],
    ethnicity: [],
  },
  procedureValues: {
    axial_length_min: "",
    axial_length_max: "",
    bcva: [],
    iop_min: "",
    iop_max: "",
    lens_status: [],
    power: [],
    sweep_count: [],
    sweep_velocity: [],
    technique: [],
    viscous_agent: false,
  },
  procedureJoinInfo: [],
};

export const Context = createContext(null);

function Provider() {
  const [queryState, setQueryState] = useState(initialState);

  function onUpdate(newState) {
    setQueryState({ ...queryState, ...newState });
  }

  function onReset() {
    setQueryState(initialState);
  }

  return (
    <Context.Provider value={queryState}>
      <QueryBuilder onUpdate={onUpdate} onReset={onReset} />
    </Context.Provider>
  );
}

export default Provider;

function QueryBuilder({ onUpdate, onReset }) {
  const {
    activeView,
    error,
    procedureJoinInfo,
    patientValues,
    procedureValues,
    loading,
  } = useContext(Context);
  const [reportData, setReportData] = useState(null);
  const [requestData, setRequestData] = useState(null);

  const queryRequestData = formatQueryRequest({
    ...patientValues,
    ...procedureValues,
    ...formatProcedureJoins(procedureJoinInfo),
  });

  function onSubmitClick() {
    onUpdate({ loading: true });

    const req = { query_data: queryRequestData };

    axios
      .post(`/query-containers/`, req)
      .then(({ data }) => {
        window.location = `/queries/${data.response[0].id}`;
        // setRequestData(req);
        // onUpdate({ loading: false });
        // setReportData({
        //   records: data.response,
        // });
        // window.scrollTo({ top: 0 });
      })
      .catch((err) => {
        setRequestData(null);
        onUpdate({ loading: false });
        toast.error(err);
      });
  }

  return (
    <div>
      {!reportData && (
        <React.Fragment>
          <QuerySummary queryRequestData={queryRequestData} />
          <ViewPicker onClick={(newVal) => onUpdate({ activeView: newVal })} />
          {activeView !== "procedure_joins" && (
            <QueryBuilderForm
              onChange={(newVal) => onUpdate({ ...newVal })}
              onPatientChange={(newVal) => {
                onUpdate({ patientValues: { ...patientValues, ...newVal } });
              }}
              onProcedureChange={(newVal) =>
                onUpdate({ procedureValues: { ...procedureValues, ...newVal } })
              }
            />
          )}
          {activeView === "procedure_joins" && (
            <JoinPickers
              onSetupChange={(e) =>
                onUpdate({
                  procedureJoinInfo: e,
                })
              }
            />
          )}
          <div style={{ marginTop: "2em" }}>
            <ViewPicker
              onClick={(newVal) => onUpdate({ activeView: newVal })}
            />
          </div>
          <StyledSubmit>
            <Button.Basic
              text="Create Query"
              primary
              // disabled={loading}
              loading={loading}
              onClick={onSubmitClick}
            />
            {/* <Button.Basic text="Reset Form" onClick={onReset} /> */}
          </StyledSubmit>
        </React.Fragment>
      )}
      {reportData && (
        <React.Fragment>
          {requestData && (
            <div style={{ marginBottom: "1.5em" }}>
              <SaveQuery queryData={requestData} />
            </div>
          )}
          {reportData.records.length === 0 && (
            <EmptyList text="No procedures matching query">
              <ClearResultsButton onClick={() => setReportData(null)} />
            </EmptyList>
          )}
          {reportData.records.length > 0 && (
            <div>
              <QueryBuilderOutput items={reportData.records} />
              <div style={{ marginTop: "1.5em" }}>
                <ClearResultsButton onClick={() => setReportData(null)} />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

const ClearResultsButton = ({ onClick }) => (
  <Button.Basic text="Back to form" primary onClick={onClick} />
);

const SaveQuery = ({ onSuccess, queryData }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button.Basic text="Save Query" onClick={() => setOpen(true)} primary />
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Content>
          <QueryContainerForm queryData={queryData} />
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button.Basic text="Cancel" onClick={onClose} />
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export const StyledSubmit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2.5em;

  button:first-of-type {
    font-size: 1.5em;
    background: var(--positiveColor);
    border-color: var(--positiveColor);
  }
`;

export const statusOptions = [
  { text: "Active", value: 1 },
  { text: "Inactive", value: 2 },
  { text: "Removed", value: 3 },
  { text: "Invited", value: 4 },
];

export const doctorVariety = [
  { text: "Medical Doctor", value: 1 },
  { text: "Trainee", value: 2 },
  { text: "Nurse", value: 3 },
  { text: "Nurse Practitioner", value: 4 },
  { text: "Physician Assistant", value: 5 },
];

import React from "react";
import DatePicker from "react-datepicker";
import { Form as SemanticForm, Label } from "semantic-ui-react";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";

import { DateField } from "@components/shared";

const CustomInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  if (props.radio) {
    return (
      <SemanticForm.Field>
        <label style={{ marginBottom: "10px" }}>{props.label}</label>
        <SemanticForm.Radio
          label={field.value ? "Yes" : "No"}
          name={field.name}
          toggle
          checked={field.value}
          onChange={(e, d) =>
            field.onChange({
              target: {
                name: d.name,
                value: d.checked,
              },
            })
          }
        />
      </SemanticForm.Field>
    );
  }
  if (props.select) {
    if (props.readOnly) {
      const selectedOpt = props.options.find((f) => f.value === field.value);
      return (
        <ReadOnlyField
          label={props.label}
          value={selectedOpt ? selectedOpt.text : ""}
        />
      );
    }

    return (
      <SemanticForm.Field required={props.required}>
        <SemanticForm.Select
          error={errors[field.name] && touched[field.name]}
          value={field.value}
          label={props.label}
          onBlur={(e, d) =>
            field.onBlur({
              target: {
                name: d.name,
                value: d.value,
              },
            })
          }
          onChange={(e, d) =>
            field.onChange({
              target: {
                name: d.name,
                value: d.value,
              },
            })
          }
          options={props.options}
          placeholder="Select one..."
          name={field.name}
          search
          required={props.required}
          // search={props.search ? true : false}
        />
        {touched[field.name] && errors[field.name] ? (
          <Label
            basic
            // color="red"
            style={{ marginTop: "0.25em" }}
            pointing
          >
            {errors[field.name]}
          </Label>
        ) : null}
      </SemanticForm.Field>
    );
  }

  if (props.textarea) {
    return (
      <SemanticForm.Field required>
        <SemanticForm.TextArea
          name={field.name}
          onBlur={field.onBlur}
          readOnly={props.readOnly ? true : false}
          onChange={field.onChange}
          label={`${props.label}${!props.required ? " (optional)" : ""}`}
          value={field.value}
          error={errors[field.name] && touched[field.name]}
        />
        {touched[field.name] && errors[field.name] && (
          <Label basic color="red" style={{ marginTop: "0.25em" }} pointing>
            {errors[field.name]}
          </Label>
        )}
      </SemanticForm.Field>
    );
  }

  if (props.datepicker) {
    if (props.readOnly) {
      return (
        <ReadOnlyField
          label={props.label}
          value={field.value ? new Date(field.value).toLocaleDateString() : ""}
        />
      );
    }

    return (
      <SemanticForm.Field required={props.required}>
        <label>{props.label}</label>
        <DateField
          minDate={props.minDate ? props.minDate : null}
          maxDate={props.maxDate ? props.maxDate : null}
          onChange={(dateObj, t) =>
            field.onChange({
              target: { name: field.name, value: dateObj },
            })
          }
          filterDate={props.dateFilter || null}
          name={field.name}
          placeholderText="Select a date..."
          selected={field.value ? field.value : null}
          showYearDropdown
          dropdownMode="select"
          autoComplete="off"
        />
        {touched[field.name] && errors[field.name] && (
          <Label basic color="red" style={{ marginTop: "0.25em" }}>
            {errors[field.name]}
          </Label>
        )}
      </SemanticForm.Field>
    );
  }

  if (props.phone) {
    return (
      <SemanticForm.Field required={field.required}>
        <label>{props.label}</label>
        <PhoneInput
          rules={{ required: true }}
          defaultCountry="GB"
          // country="UK"
          placeholder="Enter phone number"
          value={field.value}
          // value={phone}
          // onChange={setPhone}
          onChange={(e) =>
            field.onChange({
              target: { name: field.name, value: e },
            })
          }
        />
      </SemanticForm.Field>
    );
  }

  return (
    <SemanticForm.Field required>
      <SemanticForm.Input
        name={field.name}
        type="text"
        fluid
        readOnly={props.readOnly ? true : false}
        onBlur={field.onBlur}
        onChange={field.onChange}
        label={`${props.label}`}
        value={field.value}
        error={errors[field.name] && touched[field.name]}
        required={props.required ? true : false}
      />
      {touched[field.name] && errors[field.name] ? (
        <Label
          basic
          // color="red"
          style={{ marginTop: "0.25em" }}
          pointing
        >
          {errors[field.name]}
        </Label>
      ) : null}
    </SemanticForm.Field>
  );
};

export default CustomInputComponent;

export const ReadOnlyField = ({ label, value }) => {
  return (
    <SemanticForm.Field>
      <SemanticForm.Input label={label} value={value} readOnly />
    </SemanticForm.Field>
  );
};

ReadOnlyField.defaultProps = {
  value: "-",
};

import React from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";

const loaderConfig = {
  type: "ThreeDots",
  height: 10,
  width: 20,
  color: "#fff",
};

const Button = styled.button`
  padding: 0;
  line-height: 1.2;
  background: transparent;
  cursor: pointer;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: ${(props) =>
    props.active ? "4px solid var(--primaryColorLight)" : "4px solid #aaa"};
  padding-bottom: 5px;
  color: ${(props) => (props.active ? "var(--primaryColorLight)" : "#aaa")};
  /* text-shadow: ${(props) => (props.active ? "1px 1px 1px #aaa" : "none")}; */
  font-weight: 600;
  font-size: 1rem;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    // margin-left: 10px;
  }
`;

export default function TabButton({
  active,
  loading,
  style,
  onClick,
  disabled,
  text,
  type,
}) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      style={style}
      active={active}
      type={type}
    >
      {loading ? <Loader {...loaderConfig} /> : text}
    </Button>
  );
}

TabButton.defaultProps = {
  disabled: false,
  loading: false,
  active: false,
  text: "Basic",
  style: {},
  type: "button",
};

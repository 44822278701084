import React, { useState } from "react";

import { Button, FilterSelect, PaginatedTable } from "@components/shared";

const columns = [
  {
    Header: "",
    id: "view",
    width: 50,
    accessor: (d) => <Button.View url={`/registry-requests/${d.id}`} />,
  },
  {
    Header: "Patient ID",
    accessor: "patient_ident",
    width: 150,
  },
  {
    Header: "Created",
    accessor: "created_at_formatted",
    width: 100,
  },
  {
    Header: "Trust",
    accessor: "practice_name",
  },
].map((m) => ({ ...m, sortable: false }));

export default function RegistryRequestTable() {
  const [activeFilter, setActiveFilter] = useState(false);

  const additionalPostData = {
    approved: JSON.parse(activeFilter),
  };

  return (
    <React.Fragment>
      <FilterSelect
        options={dropdownOptions}
        value={activeFilter}
        onChange={(e) => setActiveFilter(e)}
        entityName="Requests"
      />
      <PaginatedTable
        columns={columns}
        additionalPostData={additionalPostData}
        entityName="Requests"
        filter={activeFilter}
        // filterOptions={filterOptions}
        keyName="data"
        url="/retrieve-registry-requests/"
      />
    </React.Fragment>
  );
}

const dropdownOptions = [
  { text: "Waiting", value: false },
  { text: "Approved", value: true },
];

import React, { useState } from "react";
import { DoctorForm, DoctorTable } from "@components/Doctor";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function DoctorIndex({ history }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Consultant Firm"
        button={
          <Button.Toggle
            closedText="Add Consultant Firm"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        }
      >
        {isOpen ? (
          <DoctorForm
            onSuccess={(doctorId) => {
              setOpen(false);
              history.push(`/doctors/${doctorId}/practices`);
            }}
          />
        ) : (
          <DoctorTable />
        )}
      </Wrapped>
    </div>
  );
}

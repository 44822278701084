import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

import { SET_ADMIN } from "@duck/admin";
import { SET_USER } from "@duck/user";

import { Admin, Auth, User } from "./containers/";

class IridexApp extends Component {
  state = {
    error: null,
    loading: true,
  };

  componentDidMount() {
    axios
      .get("/status/")
      .then(({ data }) => {
        if (!data.response[0]) {
          return;
        }

        axios.interceptors.request.use(
          (request) => {
            request.headers["server-session-token"] =
              data.response[0].server_session_token;

            return request;
          },
          (error) => {
            return Promise.reject(error);
          }
        );

        if (data.response[0].admin_id) {
          return this.props.dispatch({
            type: SET_ADMIN,
            payload: data.response[0],
          });
        }

        if (data.response[0].user_id) {
          return this.props.dispatch({
            type: SET_USER,
            payload: {
              ...data.response[0],
              session_practice_id: data.response[0].session_practice_id || null,
              // pre_approval_required: data.response[0].pre_approval_required,
            },
          });
        }
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false, error: "Status Error" });
      });
  }

  render() {
    const { error, loading } = this.state;
    const { admin, user } = this.props;

    if (error) {
      return <Auth error={true} />;
    }

    if (error) {
      return <Auth error={true} />;
    }

    if (loading) {
      return <div></div>;
    }

    if (!loading && !admin && !user) {
      return <Auth />;
    }

    if (admin) {
      return <Admin />;
    }

    if (user) {
      return <User />;
    }

    return <div></div>;
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
    user: state.user,
  };
}
export default connect(mapStateToProps)(IridexApp);

export const genderOptions = [
  { text: "Male", value: 1 },
  { text: "Female", value: 2 },
  { text: "Unknown", value: 3 },
  { text: "Non-Binary", value: 4 },
  { text: "Prefers not to say", value: 5 },
]
  .map((m) => ({ ...m, key: m.value }))
  .sort((a, b) => (a.text > b.text ? 1 : -1));

export const raceOptions = [
  { text: "Asian or Asian British", value: 1 },
  { text: "Black, Black British, Caribbean or African", value: 2 },
  { text: "Mixed or Multiple Ethnic Groups", value: 3 },
  { text: "White", value: 4 },
  { text: "Other Ethnic Group", value: 5 },
  { text: "Unknown", value: 99 },
]
  .map((m) => ({ ...m, key: m.value }))
  .sort((a, b) => (a.text > b.text ? 1 : -1));

export const ethnicityOptions = {
  // asian
  1: [
    { text: "Indian", value: 1 },
    { text: "Pakistani", value: 2 },
    { text: "Bangladeshi", value: 3 },
    { text: "Chinese", value: 4 },
    { text: "Any other Asian background", value: 5 },
  ],
  // black
  2: [
    { text: "Caribbean", value: 6 },
    { text: "African", value: 7 },
    {
      text: "Any other Black, Black British, or Caribbean background",
      value: 8,
    },
  ],
  // mixed
  3: [
    { text: "White and Black Caribbean", value: 9 },
    { text: "White and Black African", value: 10 },
    { text: "White and Asian", value: 11 },
    { text: "Any other Mixed or multiple ethnic background", value: 12 },
  ],
  // white
  4: [
    { text: "English, Welsh, Scottish, Northern Irish or British", value: 13 },
    { text: "Irish", value: 14 },
    { text: "Gypsy or Irish Traveller", value: 15 },
    { text: "Roma", value: 16 },
    { text: "Any other White background", value: 17 },
  ],
  // other
  5: [
    { text: "Arab", value: 18 },
    { text: "Any other ethnic group", value: 19 },
  ],
};

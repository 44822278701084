import React from "react";
import { useSelector } from "react-redux";

import { Wrapped } from "@components/Layouts";
import { ProcedureList } from "@components/Procedure";

export default function ProcedureIndex() {
  const practiceId = useSelector((state) => state.user.practice_id);
  if (!practiceId) return null;

  return (
    <Wrapped title="Procedures">
      <ProcedureList
        retrievalData={{
          url: `/practices/${practiceId}/retrieve-procedures/`,
          req: {
            practice_id: practiceId,
          },
        }}
      />
    </Wrapped>
  );
}

export const getRoleInfo = (state) => {
  const { admin, user } = state;

  return {
    admin_grandmaster: admin ? admin.variety === 1 : false,
    admin_research_fellow: admin ? admin.variety === 2 : false,
    admin_iridex: admin ? admin.variety === 3 : false,
    user_doctor: user ? user.role === 1 : false,
    user_proxy: user ? user.role === 2 : false,
    user_inst_admin: user ? user.role === 3 : false,
    user_originator: user ? user.role === 4 : false,
    user: user ? true : false,
    admin: admin ? true : false,
  };
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";

export default function PatientPracticeSelect({
  patientId,
  value,
  onChange,
  label,
  showAll,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (patientId) {
      fetchData();
    }
  }, [patientId]);

  function fetchData() {
    axios
      .get(`/patients/${patientId}/practices/`)
      .then(({ data }) => {
        setOptions(
          data.response.map((m) => ({
            key: m.id,
            value: m.id,
            text: m.name,
          }))
        );

        setLoading(false);

        if (data.response.length === 1) {
          onChange(data.response[0].id);
        }
      })
      .catch((err) => {
        setLoading(false);
        setOptions([]);
      });
  }

  if (options.length <= 1) {
    return null;
  }

  return (
    <Form>
      <Form.Select
        options={[
          { key: 54325345, text: "All trusts", value: null },
          ...options,
        ].filter((f) => (!showAll ? f.value : true))}
        loading={loading}
        value={value}
        onChange={(e, d) => onChange(d.value)}
        placeholder="Select a trust..."
        label={label}
      />
    </Form>
  );
}

PatientPracticeSelect.defaultProps = {
  label: "Trust",
  showAll: false,
};

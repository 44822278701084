import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import axios from "axios";

export default function PracticeSelect({ value, onChange }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .post(`/retrieve-practices/`, {
        filtered: [],
        page: 0,
        page_size: 500,
        sorted: [],
        status: 1,
        value: null,
      })
      .then(({ data }) => {
        setOptions(
          data.response[0].data
            .map((d) => ({
              key: d.id,
              value: d.id,
              text: d.name,
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        );
        setLoading(false);
      })
      .catch(() => {
        setOptions([]);
      });
  }

  return (
    <Form.Select
      placeholder="Select trust"
      loading={loading}
      options={options}
      value={value}
      search
      onChange={(e, d) => onChange(d.value)}
    />
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { DoctorForm, DoctorPractices } from "@components/Doctor";

export default function DoctorDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [doctor, setDoctor] = useState(populateDoctor());

  const doctorId = match.params.id;

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(match)]);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/doctors/${doctorId}/`)
      .then(({ data }) => {
        setLoading(false);
        setDoctor(populateDoctor(data.response[0]));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!doctor) return null;

  return (
    <div>
      <PageTitle
        title={`${doctor.full_name}`}
        subtitle="Consultant Firm"
        links={[
          { text: "Detail", to: match.url },
          { text: "Trusts", to: `${match.url}/practices` },
        ]}
      />
      <Switch>
        <Route path={match.path} exact>
          <Wrapped>
            <DoctorForm
              firstName={doctor.first_name}
              id={doctorId}
              lastName={doctor.last_name}
              prefix={doctor.prefix}
              suffix={doctor.suffix}
              onSuccess={fetchData}
            />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/practices`}>
          <DoctorPractices doctorId={doctorId} />
        </Route>
      </Switch>
    </div>
  );
}

function populateDoctor(d = {}) {
  return {
    first_name: d.first_name || "",
    full_name: d.full_name || "",
    last_name: d.last_name || "",
    prefix: d.prefix || "",
    suffix: d.suffix || "",
  };
}

import React, { useState } from "react";
import { useSelector } from "react-redux";

import { AdminForm, Table } from "@components/Admin";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

import { getRoleInfo } from "@util/roleInfo";

export default function AdminIndex() {
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <div>
      <Wrapped
        title="Admins"
        button={
          true ? (
            <Button.Toggle
              closedText="Add Admin"
              onClick={() => setOpen(!isOpen)}
              open={isOpen}
            />
          ) : null
        }
      >
        {isOpen ? <AdminForm onSuccess={() => setOpen(false)} /> : <Table />}
      </Wrapped>
    </div>
  );
}

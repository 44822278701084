import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Contact,
  Dashboard,
  DoctorsIndex,
  PatientsDetail,
  PatientsIndex,
  PostOpDetail,
  PracticeUsersIndex,
  PracticeUsersDetail,
  ProcedureDetail,
  ProcedureIndex,
  Profile,
  RegistryRequestDetail,
  RegistryRequestIndex,
  ReportsIndex,
} from "@pages/user";
import Nav from "@components/Nav";
import { AppContainer, AppView } from "@components/Layouts";
import { NoMatch } from "@components/shared";
import ScrollToTop from "./ScrollToTop";
import { getRoleInfo } from "@util/roleInfo";

export default function User(props) {
  const roleInfo = getRoleInfo(useSelector((state) => state));
  const userInfo = useSelector((state) => state).user;

  const isAdmin = roleInfo.user_inst_admin;

  return (
    <Router>
      <div style={{ height: "100%" }}>
        <Toaster
          toastOptions={{
            success: {
              iconTheme: {
                primary: "var(--positiveColor)",
              },
            },
          }}
        />
        <AppContainer>
          <Nav />
          <AppView>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/patients" component={PatientsIndex} />
              <Route path="/patients/:id" component={PatientsDetail} />
              <Route exact path="/procedures" component={ProcedureIndex} />
              <Route path="/procedures/:id" component={ProcedureDetail} />
              <Route path="/post-op-visits/:id" component={PostOpDetail} />
              <Route exact path="/profile" component={Profile} />
              <Route path="/queries" component={ReportsIndex} />
              <Route path="/contact" component={Contact} />
              {isAdmin && [
                <Route exact path="/users" component={PracticeUsersIndex} />,
                <Route
                  exact
                  path="/users/:id"
                  component={PracticeUsersDetail}
                />,
                <Route exact path="/doctors" component={DoctorsIndex} />,
              ]}
              {/* {roleInfo.user_doctor &&
                userInfo.pre_approval_required && [
                  <Route
                    exact
                    path="/registry-requests"
                    component={RegistryRequestIndex}
                  />,
                  <Route
                    path="/registry-requests/:id"
                    component={RegistryRequestDetail}
                  />,
                ]} */}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </AppView>
        </AppContainer>
      </div>
    </Router>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { PracticeForm } from "@components/Practice";
import PhoneManagement from "@components/PhoneManagement";
import { UserManagement } from "@components/PracticeUser";
import { PatientCreate, Table as PatientTable } from "@components/Patient";
import { DoctorPractices } from "@components/Doctor";
import { ProcedureList } from "@components/Procedure";

export default function PracticesDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [practice, setPractice] = useState(populatePractice());

  const practiceId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/practices/${practiceId}/`)
      .then(({ data }) => {
        setLoading(false);
        setPractice(populatePractice(data.response[0]));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!practice) return null;

  return (
    <div>
      <PageTitle
        title={practice.name}
        subtitle="Trust"
        links={[
          { text: "Detail", to: match.url },
          { text: "Users", to: `${match.url}/users` },
          { text: "Patients", to: `${match.url}/patients` },
          { text: "Consultant Firms", to: `${match.url}/doctors` },
          { text: "Procedures", to: `${match.url}/procedures` },
        ].map((l, i) => ({ ...l, key: i + 1 }))}
      />
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <Wrapped>
              <PracticeForm
                id={practiceId}
                city={practice.city}
                country={practice.country}
                name={practice.name}
                onSuccess={fetchData}
                postalCode={practice.postal_code}
                preApprovalRequired={practice.pre_approval_required}
                siteId={practice.site_ident}
                stateProvence={practice.state_provence}
                status={practice.status}
                streetAddress1={practice.street_address_1}
                streetAddress2={practice.street_address_2}
                streetAddress3={practice.street_address_3}
              />
            </Wrapped>
            <PhoneManagement practiceId={practiceId} />
          </React.Fragment>
        </Route>
        <Route path={`${match.path}/users`}>
          <UserManagement practiceId={practiceId} />
        </Route>
        <Route path={`${match.path}/patients`}>
          <PracticePatients practiceId={practiceId} />
        </Route>
        <Route path={`${match.path}/doctors`}>
          <DoctorPractices practiceId={practiceId} />
        </Route>
        <Route path={`${match.path}/procedures`}>
          <Wrapped>
            <ProcedureList
              retrievalData={{
                url: `/practices/${practiceId}/retrieve-procedures/`,
                req: {
                  practice_id: practiceId,
                },
              }}
            />
          </Wrapped>
        </Route>
      </Switch>
    </div>
  );
}

function populatePractice(d = {}) {
  return {
    city: d.city || "",
    country: d.country || "",
    name: d.name || "",
    postal_code: d.postal_code || "",
    pre_approval_required: d.pre_approval_required || false,
    site_ident: d.site_ident || "",
    state_provence: d.state_provence || "",
    status: d.status || null,
    street_address_1: d.street_address_1 || "",
    street_address_2: d.street_address_2 || "",
    street_address_3: d.street_address_3 || "",
  };
}

const PracticePatients = ({ practiceId, history }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapped
      title="Patients"
      // button={
      //   <Button.Toggle
      //     closedText="Add Patients"
      //     onClick={() => setOpen(!isOpen)}
      //     open={isOpen}
      //   />
      // }
    >
      {isOpen ? (
        <PatientCreate
          onSuccess={(newPatientId) => {
            setOpen(false);
            history.push(`/patients/${newPatientId}`);
          }}
          practiceId={practiceId}
        />
      ) : (
        <PatientTable practiceId={practiceId} isAdmin />
      )}
    </Wrapped>
  );
};

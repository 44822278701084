import React from "react";

import { Wrapped } from "@components/Layouts";

export default function Contact() {
  return (
    <Wrapped title="Contact">
      <p>Coming soon...</p>
    </Wrapped>
  );
}

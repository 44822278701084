import DoctorForm from "./DoctorForm";
import DoctorPractices from "./DoctorPractices";
import DoctorPracticeUsers from "./DoctorPracticeUsers";
import DoctorProcedure from "./DoctorProcedure";
import DoctorTable from "./DoctorTable";

export {
  DoctorForm,
  DoctorPractices,
  DoctorPracticeUsers,
  DoctorProcedure,
  DoctorTable,
};

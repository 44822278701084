import React from "react";

import { ProcedureJoins } from "./";
import formatEye from "@util/formatEye";
import { formatNameWithEye, formatMedName } from "@util/optionJoinFormatters";

export const procedureJoinKeys = {
  cataract_status: "cataractStatus",
  complication: "complication",
  glaucoma_diagnoses: "glaucomaDiagnoses",
  gonioscopy: "gonioscopy",
  ocular_comorbidites: "ocularComorbidities",
  ocular_medications: "ocularMedications",
  previous_ocular_surgery: "previousOcularSurgery",
  retinal_finding: "retinalFinding",
  sle_finding: "sleFinding",
  systemic_comorbidites: "systemicComorbidities",
  // systemic_medications: "systemicMedications",
};

const rrRoutePrefixFormatter = (id) => `/registry-requests/${id}`;

export function formatProcedureJoins(procedureId, postOpId, eye, rrId) {
  const rrRoutePrefix = rrId ? rrRoutePrefixFormatter(rrId) : null;

  return [
    {
      title: "Complications",
      entity: "PostOpComplication",
      keyName: procedureJoinKeys.complication,
      addData: {
        url: `/post-op-visits/${postOpId}/add-complications/`,
        req: { post_op_visit_id: postOpId, eye },
      },
      removeData: {
        url: `/option-status/`,
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-complications/",
        req: { status: 1 },
      },
      retrievalData: {
        url: `/post-op-visits/${postOpId}/retrieve-complications/`,
        req: { variety: 1 },
        option_key: "complication_id",
      },
      nameFormatter: (d) => formatNameWithEye(d),
      order: 6,
      procedure_association_group: "",
    },
    {
      title: "Ocular Medications",
      entity: rrId
        ? "RequestMedication"
        : postOpId
        ? "PostOpMedication"
        : "MedProcedure",
      patient_entity: "PatientMed",
      keyName: procedureJoinKeys.ocular_medications,
      selectedEye: eye,
      addData: {
        url: rrId
          ? `${rrRoutePrefix}/add-medication/`
          : postOpId
          ? `/post-op-visits/${postOpId}/add-meds/`
          : `/procedures/${procedureId}/add-meds/`,
        req: {
          eye,
          procedure_id: procedureId,
          post_op_visit_id: postOpId,
          registry_request_id: rrId,
        },
      },
      removeData: {
        url: `/option-status/`,
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-meds/",
        req: { status: 1, variety: 2 },
      },
      retrievalData: {
        url: rrId
          ? `${rrRoutePrefix}/retrieve-medications/`
          : postOpId
          ? `/post-op-visits/${postOpId}/retrieve-meds/`
          : `/procedures/${procedureId}/retrieve-meds/`,
        req: {
          variety: 2,
          eye,
        },
        option_key: "medication_id",
      },
      nameFormatter: (d) => formatMedName(d),
      isMeds: true,
      order: 4,
      procedure_association_group: "history",
    },
    // {
    //   title: "Systemic Medications",
    //   keyName: procedureJoinKeys.systemic_medications,
    //   entity: postOpId ? "PostOpMedication" : "MedProcedure",
    //   patient_entity: "PatientMed",
    //   selectedEye: eye,
    //   addData: {
    //     url: postOpId
    //       ? `/post-op-visits/${postOpId}/add-meds/`
    //       : `/procedures/${procedureId}/add-meds/`,
    //     req: { eye, procedure_id: procedureId, post_op_visit_id: postOpId },
    //   },
    //   removeData: {
    //     url: `/option-status/`,
    //     req: {},
    //   },
    //   optionsRetrievalData: {
    //     url: "/retrieve-meds/",
    //     req: { status: 1, variety: 1 },
    //   },
    //   retrievalData: {
    //     url: postOpId
    //       ? `/post-op-visits/${postOpId}/retrieve-meds/`
    //       : `/procedures/${procedureId}/retrieve-meds/`,
    //     req: {
    //       variety: 1,
    //       eye,
    //     },
    //     option_key: "medication_id",
    //   },
    //   nameFormatter: (d) => formatMedName(d),
    //   isMeds: true,
    // },
    {
      title: "Ocular Comorbidities",
      keyName: procedureJoinKeys.ocular_comorbidites,
      entity: rrId ? "RequestComorbidity" : "ProcedureComorbidity",
      patient_entity: "PatientComorbidity",
      selectedEye: eye,
      addData: {
        url: rrId
          ? `${rrRoutePrefix}/add-comorbidity/`
          : `/procedures/${procedureId}/add-comorbidity/`,
        req: { procedure_id: procedureId, registry_request_id: rrId, eye },
      },
      removeData: {
        url: "/option-status/",
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-comorbidities/",
        req: { variety: 2, status: 1 },
      },
      retrievalData: {
        url: rrId
          ? `${rrRoutePrefix}/retrieve-comorbidities/`
          : `/procedures/${procedureId}/retrieve-comorbidities/`,
        req: { variety: 2, eye },
        option_key: "comorbidity_id",
      },
      nameFormatter: (d) => formatNameWithEye(d),
      order: 5,
      procedure_association_group: "history",
    },
    {
      title: "Systemic Comorbidities",
      entity: rrId ? "RequestComorbidity" : "ProcedureComorbidity",
      patient_entity: "PatientComorbidity",
      keyName: procedureJoinKeys.systemic_comorbidites,
      selectedEye: eye,
      addData: {
        url: rrId
          ? `${rrRoutePrefix}/add-comorbidity/`
          : `/procedures/${procedureId}/add-comorbidity/`,
        req: { procedure_id: procedureId, registry_request_id: rrId, eye },
      },
      removeData: {
        url: `/option-status/`,
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-comorbidities/",
        req: { variety: 1, status: 1 },
      },
      retrievalData: {
        url: rrId
          ? `${rrRoutePrefix}/retrieve-comorbidities/`
          : `/procedures/${procedureId}/retrieve-comorbidities/`,
        req: { variety: 1, eye },
        option_key: "comorbidity_id",
      },
      nameFormatter: (d) => formatNameWithEye(d),
      order: 3,
      procedure_association_group: "history",
    },
    {
      title: "Glaucoma Diagnoses*",
      keyName: procedureJoinKeys.glaucoma_diagnoses,
      entity: rrId ? "RequestDiagnosis" : "DiagnosisProcedure",
      patient_entity: "PatientDiagnosis",
      selectedEye: eye,
      addData: {
        url: rrId
          ? `${rrRoutePrefix}/add-diagnosis/`
          : `/procedures/${procedureId}/add-diagnosis/`,
        req: { eye, procedure_id: procedureId, registry_request_id: rrId },
      },
      removeData: {
        url: `/option-status/`,
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-diagnosis/",
        req: { status: 1 },
      },
      retrievalData: {
        url: rrId
          ? `${rrRoutePrefix}/retrieve-diagnoses/`
          : `/procedures/${procedureId}/retrieve-diagnoses/`,
        req: { eye },
        option_key: "glaucoma_diagnosis_id",
      },
      isDiagnosis: true,
      nameFormatter: (d) => {
        return `${d.name}${d.value ? ` ${d.value}` : ""}${
          ![null, undefined].includes(d.eye) ? ` - ${formatEye(d.eye)}` : ""
        }`;
      },
      order: 2,
      procedure_association_group: "history",
    },
    {
      title: "Previous Ocular Surgery",
      keyName: procedureJoinKeys.previous_ocular_surgery,
      entity: rrId ? "RequestSurgery" : "PosProcedure",
      patient_entity: "PosPatient",
      selectedEye: eye,
      addData: {
        url: rrId
          ? `${rrRoutePrefix}/add-surgery/`
          : `/procedures/${procedureId}/add-pos/`,
        req: { eye, procedure_id: procedureId, registry_request_id: rrId },
      },
      removeData: {
        url: `/option-status/`,
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-pos/",
        req: { status: 1 },
      },
      retrievalData: {
        url: rrId
          ? `${rrRoutePrefix}/retrieve-surgeries/`
          : `/procedures/${procedureId}/retrieve-pos/`,
        req: { eye },
        option_key: "previous_ocular_surgery_id",
      },
      nameFormatter: (d) => formatNameWithEye(d),
      isPos: true,
      order: 1,
      procedure_association_group: "history",
    },
    {
      title: "Cataract Status",
      keyName: procedureJoinKeys.cataract_status,
      entity: rrId
        ? "RequestCataractStatus"
        : postOpId
        ? "CataractPostOpVisit"
        : "CataractProcedure",
      patient_entity: "CataractPatient",
      isCataractStatus: true,
      addData: {
        url: rrId
          ? `${rrRoutePrefix}/add-cataract-status/`
          : postOpId
          ? `/post-op-visits/${postOpId}/add-cataract-status/`
          : `/procedures/${procedureId}/add-cataract-statuses/`,
        req: {
          post_op_id: postOpId,
          procedure_id: procedureId,
          registry_request_id: rrId,
          eye,
        },
      },
      removeData: {
        url: `/option-status/`,
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-cataract-statuses/",
        req: { status: 1 },
      },
      retrievalData: {
        url: rrId
          ? `${rrRoutePrefix}/retrieve-cataract-statuses/`
          : postOpId
          ? `/post-op-visits/${postOpId}/retrieve-cataract-statuses/`
          : `/retrieve-cataract-procedures/`,
        req: {
          post_op_id: postOpId,
          procedure_id: procedureId,
          variety: 1,
          registry_request_id: rrId,
        },
        option_key: "cataract_status_id",
      },
      nameFormatter: (d) => formatNameWithEye(d),
      order: 6,
      procedure_association_group: "examination",
    },
    {
      title: "SLE Finding",
      keyName: procedureJoinKeys.sle_finding,
      entity: rrId
        ? "RequestSleFinding"
        : postOpId
        ? "SlePostOpVisit"
        : "SleProcedure",
      patient_entity: "SlePatient",
      addData: {
        url: rrId
          ? `${rrRoutePrefix}/add-sle-findings/`
          : postOpId
          ? `/post-op-visits/${postOpId}/add-sle-findings/`
          : `/procedures/${procedureId}/add-sle-findings/`,
        req: {
          procedure_id: procedureId,
          eye,
          post_op_visit_id: postOpId,
          registry_request_id: rrId,
        },
      },
      removeData: {
        url: `/option-status/`,
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-sle-findings/",
        req: { status: 1 },
      },
      retrievalData: {
        url: rrId
          ? `${rrRoutePrefix}/retrieve-sle-findings/`
          : postOpId
          ? `/post-op-visits/${postOpId}/retrieve-sle-findings/`
          : `/retrieve-sle-procedures/`,
        req: {
          procedure_id: procedureId,
          registry_request_id: rrId,
          variety: 1,
        },
        option_key: "sle_finding_id",
      },
      nameFormatter: (d) => formatNameWithEye(d),
      order: 7,
      procedure_association_group: "examination",
    },
    {
      title: "Gonioscopy",
      keyName: procedureJoinKeys.gonioscopy,
      entity: "GonioscopyProcedure",
      patient_entity: "GonioscopyPatient",
      isGonioscopy: true,
      addData: {
        url: rrId
          ? `${rrRoutePrefix}/add-gonioscopies/`
          : `/procedures/${procedureId}/add-gonioscopies/`,
        req: { procedure_id: procedureId, registry_request_id: rrId, eye },
      },
      removeData: {
        url: `/option-status/`,
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-gonioscopies/",
        req: { status: 1 },
      },
      retrievalData: {
        url: rrId
          ? `${rrRoutePrefix}/retrieve-gonioscopy-procedures/`
          : `/retrieve-gonioscopy-procedures/`,
        req: { procedure_id: procedureId, registry_request_id: rrId },
        option_key: "gonioscopy_id",
      },
      nameFormatter: (d) => formatNameWithEye(d),
      order: 8,
      procedure_association_group: "examination",
    },
    {
      title: "Retinal Finding",
      keyName: procedureJoinKeys.retinal_finding,
      entity: rrId
        ? "RequestRetinalFinding"
        : postOpId
        ? "RfPostOpVisit"
        : "RfProcedure",
      patient_entity: "RfPatient",
      isRetinalFinding: true,
      addData: {
        url: rrId
          ? `${rrRoutePrefix}/add-retinal-findings/`
          : postOpId
          ? `/post-op-visits/${postOpId}/add-retinal-findings/`
          : `/procedures/${procedureId}/add-retinal-findings/`,
        req: {
          procedure_id: procedureId,
          eye,
          post_op_visit_id: postOpId,
          registry_request_id: rrId,
        },
      },
      removeData: {
        url: `/option-status/`,
        req: {},
      },
      optionsRetrievalData: {
        url: "/retrieve-retinal-findings/",
        req: { status: 1 },
      },
      retrievalData: {
        url: rrId
          ? `${rrRoutePrefix}/retrieve-retinal-findings/`
          : postOpId
          ? `/post-op-visits/${postOpId}/retrieve-retinal-findings/`
          : `/retrieve-rf-procedures/`,
        req: {
          procedure_id: procedureId,
          variety: 2,
          registry_request_id: rrId,
        },
        option_key: "retinal_finding_id",
      },
      nameFormatter: (d) => formatNameWithEye(d),
      order: 9,
      procedure_association_group: "examination",
    },
  ]
    .sort((a, b) => a.order - b.order)
    .map((m, i) => ({ ...m, key: i + 1 }));
}

export default function ProcedureJoinManagement({
  detailMode,
  filters,
  postOpId,
  procedureId,
  isAdmin,
  selectedEye,
  viewOnly,
  rrId,
  fetchData,
  groupName,
}) {
  const joins = formatProcedureJoins(procedureId, postOpId, selectedEye, rrId);

  return (
    <React.Fragment>
      {joins
        .filter((f) =>
          !postOpId ? !["PostOpComplication"].includes(f.entity) : true
        )
        .filter((f) =>
          rrId ? !["GonioscopyProcedure"].includes(f.entity) : true
        )
        .filter((f) => (filters.length ? filters.includes(f.keyName) : true))
        .filter((f) =>
          groupName ? f.procedure_association_group === groupName : true
        )
        .map((m) => (
          <ProcedureJoins
            key={m.key}
            isAdmin={isAdmin}
            selectedEye={selectedEye}
            detailMode={detailMode}
            postOpId={postOpId}
            rrId={rrId}
            viewOnly={viewOnly}
            onUpdate={fetchData}
            removeData={{}}
            {...m}
          />
        ))}
    </React.Fragment>
  );
}

ProcedureJoinManagement.defaultProps = {
  detailMode: false,
  filters: [], // filter down the joins with an arr of keyNames
  procedureId: null,
  selectedEye: null,
  viewOnly: false,
};

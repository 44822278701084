import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FaExclamation } from "react-icons/fa";

import { Switch, Route } from "react-router-dom";
import styled from "styled-components";

import { Wrapped } from "@components/Layouts";
import { Button, Notes, PageTitle, ViewError } from "@components/shared";
import { PostOpManagement } from "@components/PostOp";
import {
  ProcedureFormIops,
  ProcedureJoinManagement,
  ProcedureForm,
  ProcedureList,
} from "@components/Procedure";
import { AuditManagement } from "@components/Audit";
import { ComplicationManagement } from "@components/Complication";
import { DoctorProcedure } from "@components/Doctor";

import { getRoleInfo } from "@util/roleInfo";

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 0;
  button {
    width: 50%;
    font-size: 16px;
  }

  button + button {
    // margin-left: 5px;
  }
`;

export default function ProcedureDetail({ isAdmin, match, history }) {
  const [procedure, setProcedure] = useState(populateProcedure());
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const id = match.params.id;

  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchData();
  }, [isOpen]);

  function fetchData() {
    // setOpen(false);

    axios
      .get(`/procedures/${id}/`)
      .then(({ data }) => {
        setProcedure(populateProcedure(data.response[0]));
        setLoaded(true);
      })
      .catch((err) => {
        setError(err);
        setLoaded(false);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  const ProcedureDisplay = () => (
    <ProcedureList
      procedures={[procedure]}
      showLink={false}
      isAdmin={isAdmin}
      fetchDetailData={fetchData}
    />
  );

  const ProcedureEdit = ({ setOpen }) => {
    const FORM_VIEW = "form";
    const HISTORY_VIEW = "history";
    const EXAMINATION_VIEW = "examination";
    const [editView, setEditView] = useState(FORM_VIEW);
    const [check, setCheck] = useState(false);

    function onUpdate() {
      toast.success("Updated!");
      setOpen(false);
    }

    function commitProcedureCheck() {
      if (!check) {
        setCheck(true);
      } else {
        commitProcedure();
      }
    }

    function commitProcedure() {
      axios
        .get(`/procedures/${id}/commit/`)
        .then(({ data }) => {
          toast.success("Committed!");
          setOpen(false);
          setCheck(false);
        })
        .catch((err) => {
          setError(err);
          setOpen(false);
          setCheck(false);
        });
    }

    return (
      <React.Fragment>
        <div style={{ marginTop: "3em", marginBottom: "1em" }}>
          {(!procedure.committed_to_registry || roleInfo.user_inst_admin) && (
            <ProcedureFormIops
              items={procedure.iop_entries}
              procedureId={id}
              fetchData={fetchData}
              iop={procedure.iop}
            />
          )}
          {(roleInfo.admin_research_fellow ||
            roleInfo.user_inst_admin ||
            !procedure.committed_to_registry) && (
            <BtnWrapper>
              {[
                { text: "Procedure", value: FORM_VIEW },
                // { text: "Procedure Associations", value: JOIN_VIEW },
                { text: "History", value: HISTORY_VIEW },
                { text: "Examination Findings", value: EXAMINATION_VIEW },
              ].map((m, i) => (
                <Button.Tab
                  key={i}
                  text={m.text}
                  active={m.value === editView}
                  onClick={() => setEditView(m.value)}
                />
              ))}
            </BtnWrapper>
          )}
        </div>
        {(!procedure.committed_to_registry || roleInfo.user_inst_admin) &&
          editView === FORM_VIEW && (
            <div>
              <ProcedureForm
                roleInfo={roleInfo}
                procedureData={procedure}
                id={id}
                fetchData={() => fetchData()}
                onUpdateSuccess={() => onUpdate()}
              />
            </div>
          )}
        {(!procedure.committed_to_registry || roleInfo.user_inst_admin) &&
          editView === EXAMINATION_VIEW && (
            <React.Fragment>
              {loaded && (
                <div style={{ marginTop: "2em" }}>
                  <ProcedureJoinManagement
                    procedureId={id}
                    selectedEye={procedure.eye}
                    detailMode
                    groupName="examination"
                  />
                </div>
              )}
              <Button.Text
                text="I'm done editing"
                onClick={() => setOpen(false)}
                style={{ color: "var(--primaryColor)" }}
              />
            </React.Fragment>
          )}
        {(!procedure.committed_to_registry || roleInfo.user_inst_admin) &&
          editView === HISTORY_VIEW && (
            <React.Fragment>
              {loaded && (
                <div style={{ marginTop: "2em" }}>
                  <ProcedureJoinManagement
                    procedureId={id}
                    selectedEye={procedure.eye}
                    detailMode
                    groupName="history"
                  />
                </div>
              )}
              <Button.Text
                text="I'm done editing"
                onClick={() => setOpen(false)}
                style={{ color: "var(--primaryColor)" }}
              />
            </React.Fragment>
          )}
        {/* {procedure.can_commit && !procedure.committed_to_registry && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              margin: "10px auto",
            }}
          >
            {check && (
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "#a12a32",
                }}
              >
                Warning: You will not be able to edit this once it is committed.
                Are you sure you want to commit?
              </p>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button.Basic
                text={!check ? "Commit to registry" : "Yes, I'm sure"}
                onClick={() => commitProcedureCheck()}
                style={{
                  color: check ? "#12b370" : "#444",
                  fontWeight: "bold",
                  border: "none",
                  margin: "0 auto",
                }}
              />
              {check && (
                <Button.Basic
                  text={"No"}
                  onClick={() => setCheck(false)}
                  style={{
                    color: "#444",
                    fontWeight: "bold",
                    border: "none",
                    margin: "0 auto",
                  }}
                />
              )}
            </div>
          </div>
        )} */}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <PageTitle
        title={`Procedure`}
        subtitle={
          <Button.Link
            to={`/patients/${procedure.patient_id}`}
            text="View Patient"
          />
        }
        links={[
          { text: "Details", to: match.url },
          { text: "Post-Op Visits", to: `${match.url}/post-op-visits` },
          { text: "Audits", to: `${match.url}/audits`, admin: true },
        ]
          .filter((f) => (!isAdmin ? !f.admin : true))
          .map((m, i) => ({ ...m, key: i + 1 }))}
      />
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <Wrapped
              title={
                !procedure.committed_to_registry || roleInfo.user_inst_admin
                  ? "Edit Procedure"
                  : "Procedure Details"
              }
              button={
                !procedure.committed_to_registry || roleInfo.user_inst_admin ? (
                  <Button.Toggle
                    disabled={
                      procedure.committed_to_registry &&
                      !roleInfo.user_inst_admin
                    }
                    edit
                    onClick={() => setOpen(!isOpen)}
                    open={isOpen}
                  />
                ) : null
              }
            >
              {isOpen ? (
                <ProcedureEdit setOpen={setOpen} />
              ) : (
                <ProcedureDisplay />
              )}
            </Wrapped>
            {!isOpen && (
              <React.Fragment>
                <ComplicationManagement
                  procedureId={id}
                  minDate={procedure.operation_date}
                />
                <Wrapped title="Doctor">
                  <DoctorProcedure
                    procedureId={id}
                    doctorId={procedure.doctor_id}
                    doctorName={procedure.doctor_name}
                    doctorOccupationalVariety={
                      procedure.doctor_occupational_variety
                    }
                    fetchData={fetchData}
                    readOnly={
                      roleInfo.user_inst_admin
                        ? false
                        : procedure.committed_to_registry
                    }
                  />
                </Wrapped>
              </React.Fragment>
            )}
          </React.Fragment>
        </Route>
        <Route path={`${match.path}/post-op-visits`}>
          <React.Fragment>
            <Wrapped>
              <ProcedureDisplay />
            </Wrapped>
            <PostOpManagement
              isAdmin={isAdmin}
              procedureDate={procedure.operation_date}
              procedureId={id}
              patientId={procedure.patient_id}
              onUpdate={fetchData}
              disabled={!procedure.committed_to_registry}
              onCreate={(newVisitId) =>
                history.push(`/post-op-visits/${newVisitId}`)
              }
            />
          </React.Fragment>
        </Route>
        {isAdmin && (
          <Route path={`${match.path}/audits`}>
            <Wrapped>
              <AuditManagement
                reqInfo={{ procedure_id: id }}
                updateInfo={{
                  xhr: (d) => axios.put(`/procedures/${id}/`, d),
                  req: { procedure_id: id },
                }}
                onUpdate={fetchData}
              />
            </Wrapped>
          </Route>
        )}
      </Switch>
      <Notes
        additionalCreateData={{ procedure_id: id }}
        additionalFetchData={{ procedure_id: id }}
        title="Procedure Notes"
      />
    </React.Fragment>
  );
}

export function populateProcedure(data = { anesthesia_procedures: [] }) {
  const activeAnesthesia = data.anesthesia_procedures[0];
  return {
    age: data.age !== undefined ? data.age : "",
    formatted_patient_name: data.formatted_patient_name
      ? data.formatted_patient_name
      : "",
    anesthesia:
      data.anesthesia_procedures && activeAnesthesia
        ? {
            option_id: activeAnesthesia.anesthesia_id,
            join_id: activeAnesthesia.id,
          }
        : null,
    axial_length: data.axial_length || "",
    bcva_method: data.bcva_method || null,
    bcva: data.bcva || "",
    operation_date: data.operation_date ? new Date(data.operation_date) : null,
    operation_date_formatted: data.operation_date_formatted || "",
    doctor_id: data.doctor_id || "",
    doctor_occupational_variety: data.doctor_occupational_formatted || "",
    doctor_name: data.doctor_name || "",
    diagnosis_procedures: data.diagnosis_procedures || [],
    duration: data.duration || null,
    duty_cycle: data.duty_cycle || "",
    ethnicity: data.ethnicity || "",
    eye: data.eye !== undefined ? data.eye : null,
    gender: data.gender || "",
    id: data.id,
    iop_variety: data.iop_variety,
    iop_entries: data.iop_entries || [],
    iop: data.iop || "",
    laser_parameter: data.laser_parameter || null,
    lens_status: data.lens_status,
    join_names: data.join_names || [],
    med_procedures: data.med_procedures || [],
    number_of_spots: data.number_of_spots || null,
    patient_id: data.patient_id || null,
    post_op_visits_array: data.post_op_visits_array || [],
    power_formatted: data.power_formatted || "",
    power: data.power || null,
    practice_id: data.practice_id || null,
    practice_name: data.practice_name || "",
    procedure_comorbidities: data.procedure_comorbidities || [],
    refraction: data.refraction || "",
    rfnl_thickness: data.rfnl_thickness || "",
    sphere: data.sphere || "",
    cylinder: data.cylinder || "",
    axis: data.axis || "",
    mean_deviation: data.mean_deviation || "",
    vfi: data.vfi || "",
    psd: data.psd || "",
    status: data.status,
    sweep_count: data.sweep_count || "",
    sweep_count_formatted: data.sweep_count_formatted || "",
    sweep_velocity: data.sweep_velocity || "",
    sweep_velocity_formatted: data.sweep_velocity_formatted || "",
    technique: data.technique || "",
    viscous_agent: data.viscous_agent || false,
    can_commit: data.can_commit || false,
    committed_to_registry: data.committed_to_registry || false,
  };
}

import React from "react";
import styled from "styled-components";
import { InlineLoader } from "@components/shared";
import { FaArrowCircleRight, FaArrowCircleDown } from "react-icons/fa";

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => (props.secondary ? "1.1em" : "1.25em")};
  font-weight: bold;

  & + div {
    margin-top: 1em;
  }

  button {
    padding: 0;
    margin-right: 5px;
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    font-weight: bold;

    svg {
      margin-right: 5px;
      color: ${(props) =>
        props.secondary
          ? `var(--primaryColorDark)`
          : `var(--primaryColorLight)`};
    }
  }

  p {
    flex-grow: 1;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 400;
  }
`;

export default function ToggleTitle({
  activeCount,
  isOpen,
  loading,
  onClick,
  secondary,
  title,
  viewOnly,
}) {
  return (
    <Title secondary={secondary}>
      {!viewOnly && (
        <button onClick={onClick}>
          {isOpen ? <FaArrowCircleDown /> : <FaArrowCircleRight />}
          {title}
        </button>
      )}
      {viewOnly && (
        <h2 style={{ marginBottom: "0" }}>
          {title} {activeCount > 0 ? `(${activeCount})` : ""}
        </h2>
      )}
      {!viewOnly && (
        <React.Fragment>
          {activeCount > 0 ? `(${activeCount})` : ""}{" "}
        </React.Fragment>
      )}
      {loading && <InlineLoader />}
    </Title>
  );
}

ToggleTitle.defaultProps = {
  activeCount: 0,
  isOpen: false,
  loading: false,
  secondary: false,
  title: "",
  viewOnly: false,
};

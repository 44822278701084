import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import {
  FaHome,
  FaUsers,
  FaUser,
  FaRegBuilding,
  FaRegUserCircle,
  FaChartBar,
} from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import styled from "styled-components";
import { cssVar, lighten } from "polished";
import { useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";

import logo from "@assets/images/iridex-white-stacked.png";
import { size, device } from "@util/breakpoints";
import { UserSetPractice } from "@components/PracticeUser";

import { getRoleInfo } from "@util/roleInfo";

const NavWrapper = styled.div`
  display: flex;
  background: var(--navBackgroundColor);
  flex-direction: column;
  width: 15%;
  max-width: 260px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  padding: 5px 0px 25px 0px;
  overflow-y: auto;
  /* z-index: 9999; */

  @media (max-width: ${size.tablet}) {
    position: relative;
    top: 0;
    width: 100%;
    max-width: 100%;
    padding: 0 0 15px 0;
    height: auto;
  }

  @media (min-width: 770px) and (max-width: 920px) {
    /* width: 98%; */
  }

  img {
    width: 245px;
    padding: 10px;
    margin: 0 auto;
    display: block;

    @media (min-width: 770px) and (max-width: 1199px) {
      display: none !important;
    }

    @media (max-width: ${size.tablet}) {
      width: 125px;
      padding: 0;
      display: none;
    }
  }

  .links {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @media (max-width: ${size.tablet}) {
      flex-direction: row;
      margin-top: 10px;
      overflow-x: auto;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
    }
  }

  a {
    color: #ebebeb;
    font-weight: 600;
    padding: 20px 3px;
    font-size: 1.05em;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 17px;
    margin-right: 17px;
    opacity: 0.7;
    border-left: 4px solid transparent;

    @media (max-width: ${size.tablet}) {
      padding: 5px 1px;
      font-size: 0.95em;
      margin-left: 5px;
      margin-right: 5px;
    }

    span.text {
      @media (max-width: 1058px) {
        display: none;
      }
    }

    span {
      margin-left: 20px;
    }

    &:hover {
      color: white;
      opacity: 0.95;
    }

    &.active {
      color: ${() => `${lighten(0.15, cssVar("--primaryColorLight"))}`};
      /* color: lighten($primary-color-light, 15%); */
      opacity: 1;
      border-left: ${() =>
        `3px solid ${lighten(0.11, cssVar("--primaryColorLight"))}`};
      border-radius: 0px;

      @media (max-width: ${size.tablet}) {
        border-color: transparent;
      }
    }

    svg {
      margin-left: 25px;
      font-size: 1.25em;

      @media (min-width: 1059px) {
        display: none;
      }
    }
  }

  .admin-info {
    color: #fff;
    font-weight: bold;
    text-align: center;
    margin: 5px auto;
    opacity: 0.45;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 15px;
    margin: 5px auto;
    width: fit-content;
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    opacity: 0.45;

    &:hover {
      opacity: 1;
    }

    @media (max-width: ${size.tablet}) {
      display: none;
    }
  }
`;

export default function Nav({ admin }) {
  function logout(e) {
    e.preventDefault();

    const url = admin ? `/logout/` : `/logout/`;

    axios
      .post(url)
      .then(() => {
        window.location = admin ? `/admin` : "/";
      })
      .catch(() => {
        window.location = "/";
      });
  }

  const storeInfo = useSelector((state) => state);
  const roleInfo = getRoleInfo(storeInfo);
  const adminInfo = storeInfo.admin;
  const userInfo = storeInfo.user;

  const linkset = admin
    ? adminLinks.filter((f) =>
        roleInfo.admin_research_fellow
          ? !["consultant firms", "users", "options", "admins"].includes(f.name)
          : true
      )
    : userLinks
        .filter((f) =>
          roleInfo.user_doctor ||
          roleInfo.user_proxy ||
          roleInfo.user_originator
            ? !["users", "consultant firms"].includes(f.name)
            : true
        )
        .filter((f) =>
          !roleInfo.user_doctor || !userInfo.pre_approval_required
            ? !["requests"].includes(f.name)
            : true
        );

  const showNoEntry = false;

  return (
    <NavWrapper>
      {showNoEntry && (
        <Modal open size="small">
          <Modal.Content>
            <p>
              There is nothing for you to do at the moment. Please contact
              Iridex for support.
            </p>
          </Modal.Content>
        </Modal>
      )}
      <img src={logo} alt="IRIDEX Registry" />
      <div className="links">
        {linkset.map(({ key, text, path, icon }) => (
          <NavLink key={key} to={path} exact={path === "/"}>
            {icon} <span className="text">{text}</span>
          </NavLink>
        ))}
        <NavLink to={"/profile"}>
          <FaUser /> <span className="text">My Profile</span>
        </NavLink>
      </div>
      {adminInfo && (
        <div className="admin-info">{adminInfo.variety_formatted}</div>
      )}
      {!admin && <UserSetPractice />}
      <button onClick={logout} title="Logout">
        <BiLogOut />
      </button>
    </NavWrapper>
  );
}

Nav.defaultProps = {
  admin: false,
};

const userLinks = [
  { text: "Dashboard", path: `/`, icon: <FaHome /> },
  { text: "Audit", path: "/queries", icon: <FaChartBar /> },
  { text: "Patients", path: "/patients", icon: <FaRegUserCircle /> },
  { text: "Procedures", path: "/procedures", icon: <FaChartBar /> },
  // {
  //   text: "Consultant Firms",
  //   path: `/doctors`,
  // },
  {
    text: "Users",
    path: "/users",
    icon: <FaUsers />,
  },
  {
    text: "Requests",
    path: `/registry-requests`,
    icon: <FaUsers />,
  },
  {
    text: "Contact Registry",
    path: `/contact`,
    icon: <FaUsers />,
  },
].map((m, i) => ({ ...m, key: i + 1, name: m.text.toLowerCase() }));

const adminLinks = [
  { text: "Dashboard", path: `/`, icon: <FaHome /> },
  { text: "Trusts", path: `/practices`, icon: <FaRegBuilding /> },
  { text: "Patients", path: `/patients`, icon: <FaUser /> },
  { text: "Reports", path: "/queries", icon: <FaChartBar /> },
  {
    text: "Users",
    path: `/users`,
  },
  {
    text: "Consultant Firms",
    path: `/doctors`,
  },
  {
    text: "Imperial Admins",
    path: "/admins",
  },
  {
    text: "Clinical",
    path: `/options`,
    icon: <FaUsers />,
  },
  {
    text: "Requests",
    path: `/registry-requests`,
    icon: <FaUsers />,
  },
].map((m, i) => ({ ...m, key: i + 1, name: m.text.toLowerCase() }));

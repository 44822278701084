import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  BasicGrid,
  Card,
  EmptyList,
  FilterSelect,
  InlineLoader,
  Icon,
  Button,
} from "@components/shared";

const StyledList = styled.div`
  h2 {
    display: flex;
    align-items: center;
  }

  & + & {
    margin-top: 1em;
  }
`;

export default function QueryContainerList({ title, fetchUrl, isAdmin }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState(1);

  const dropdownOptions = [
    { text: "All", value: 1 },
    { text: "My Queries", value: 2 },
    { text: isAdmin ? "System-wide Queries" : "My Trust Queries", value: 3 },
    { text: "Iridex Queries", value: 4 },
  ].filter((f) => (isAdmin ? ![4].includes(f.value) : true));

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(fetchUrl)
      .then(({ data }) => {
        const responseData = data.response[0];
        setLoading(false);
        setItems(
          [
            ...responseData.queries.map((m) => ({
              ...m,
              editable: true,
              practice: m.global ? true : false,
            })),
            ...responseData.practice_queries.map((m) => ({
              ...m,
              practice: true,
            })),
            ...responseData.admin_queries.map((m) => ({
              ...m,
              admin: true,
            })),
          ].sort((a, b) => (a.name > b.name ? 1 : -1))
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  const filteredQueries = [...items]
    .filter((f) => (activeFilter === 2 ? f.editable : true))
    .filter((f) => (activeFilter === 3 ? f.practice : true))
    .filter((f) => (activeFilter === 4 ? f.admin : true));

  return (
    <StyledList>
      {loading && <h2>{loading && <InlineLoader />}</h2>}
      <FilterSelect
        options={dropdownOptions}
        value={activeFilter}
        onChange={(e) => setActiveFilter(e)}
        entityName="Queries"
        filterText={`Now Showing:`}
      />
      {!filteredQueries.length && <EmptyList text={`No ${title} Yet...`} />}
      <BasicGrid columns={2}>
        {filteredQueries.map((item) => (
          <Link to={`/queries/${item.id}`}>
            <Card.Wrapper key={item.id}>
              <Card.Meta
                hasActions={false}
                title={item.name}
                description={item.description}
                labels={[
                  {
                    text: "Crafted by me",
                    value: item.editable ? true : false,
                  },
                  // {
                  //   text: isAdmin ? "System-wide" : "Practice Wide",
                  //   value: item.practice ? true : false,
                  // },
                  {
                    text: "Crafted by Iridex",
                    value: item.admin ? true : false,
                  },
                  {
                    text: isAdmin ? "System Wide" : "Trust Wide",
                    value: isAdmin ? false : item.shared_practice,
                  },
                ].filter((f) => f.value)}
              />
            </Card.Wrapper>
          </Link>
        ))}
      </BasicGrid>
    </StyledList>
  );
}

QueryContainerList.defaultProps = {
  fetchUrl: `/query-containers/`,
};

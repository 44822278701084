import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Modal } from "semantic-ui-react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { FaPlusCircle, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";

import { Button, Icon } from "@components/shared";
import { getRoleInfo } from "@util/roleInfo";

export default function DoctorPracticeUsers({
  joinId,
  users,
  practiceId,
  fetchData,
}) {
  if (!joinId) return null;

  const doctorUsers = users.filter((f) => f.variety === 1);
  const proxyUsers = users.filter((f) => f.variety === 2);

  return (
    <div>
      <List>
        <ListTitle>
          <AddForm
            variety={1}
            practiceId={practiceId}
            joinId={joinId}
            onSuccess={fetchData}
            disabled={doctorUsers.filter((f) => f.status === 1).length > 0}
          />
          <UserList
            joinId={joinId}
            doctor
            users={doctorUsers}
            emptyText="No doctor yet"
            fetchData={fetchData}
          />
        </ListTitle>
      </List>
      <List>
        <ListTitle>
          <AddForm
            variety={2}
            practiceId={practiceId}
            joinId={joinId}
            onSuccess={fetchData}
            disabledIds={proxyUsers
              .filter((f) => f.status !== 2)
              .map((m) => m.user_id)}
            // filteredIds={doctorUsers.map((m) => m.user_id)}
          />
          <UserList
            joinId={joinId}
            users={proxyUsers}
            emptyText="No proxies yet"
            fetchData={fetchData}
          />
        </ListTitle>
      </List>
    </div>
  );
}

DoctorPracticeUsers.defaultProps = {
  users: [],
};

const AddForm = ({
  joinId,
  variety,
  practiceId,
  onSuccess,
  disabled,
  disabledIds,
  filteredIds,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  function onClose(reload = false) {
    setOpen(false);
    setSelected(null);
    setLoading(false);

    if (reload) {
      onSuccess();
    }
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      user_id: selected,
      doctor_practice_id: joinId,
      variety,
    };

    axios
      .post(`/doctor-user-join/`, req)
      .then(() => {
        toast.success("Added!");
        onClose(true);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <Button.Basic
        text={
          <React.Fragment>
            <span>{variety === 1 ? "Doctor" : "Proxies"}</span>{" "}
            {!disabled && !roleInfo.admin_research_fellow && <FaPlusCircle />}
          </React.Fragment>
        }
        onClick={() => {
          if (disabled) return;

          setOpen(true);
        }}
      />
      <Modal open={isOpen} onClose={() => onClose()} size="small">
        <Modal.Header
          content={variety === 1 ? "Adding Doctor" : "Adding Proxy"}
        />
        <Modal.Content>
          <Form>
            <PracticeUserSelect
              value={selected}
              onChange={(e) => setSelected(e)}
              practiceId={practiceId}
              disabledIds={disabledIds}
              filteredIds={filteredIds}
              reqData={{
                for_doctor: variety === 1,
                for_proxy: variety === 2,
              }}
            />
            <Button.Basic
              primary
              onClick={onSubmit}
              disabled={loading || !selected}
              text="Add"
              loading={loading}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button.Basic
            text="Close"
            onClick={() => onClose()}
            disabled={loading}
          />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

AddForm.defaultProps = {
  disabledIds: [],
  filteredIds: [],
};

const PracticeUserSelect = ({
  onChange,
  value,
  practiceId,
  disabledIds,
  filteredIds,
  reqData,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  if (!practiceId) return <p>No practice selected</p>;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    const req = {
      ...reqData,
      filtered: [],
      page: 0,
      page_size: 500,
      practice_id: practiceId,
      sorted: [],
      status: 1,
    };

    axios
      .post(`/retrieve-users/`, req)
      .then(({ data }) => {
        setLoading(false);
        setOptions(
          data.response[0].data
            .map((d) => ({
              key: d.id,
              text: `${d.first_name} ${d.last_name}`,
              value: d.id,
              disabled: disabledIds.includes(d.id),
              description: disabledIds.includes(d.id) ? "Already added" : "",
            }))
            .filter((f) => !filteredIds.includes(f.value))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        );
      })
      .catch((err) => {
        setLoading(false);
        setOptions([]);
      });
  }

  return (
    <Form.Select
      placeholder="Select one..."
      options={options}
      value={value}
      loading={loading}
      search
      onChange={(e, d) => onChange(d.value)}
    />
  );
};

PracticeUserSelect.defaultProps = {
  disabledIds: [],
  filteredIds: [],
  reqData: {},
};

const EmptyStyle = styled.p`
  color: #fff;
  padding: 20px;
  margin-top: 1em;
  background: var(--primaryColor);
  opacity: 0.45;
  font-weight: bold;
  border-radius: 8px;
`;

const UserList = ({ users, emptyText, fetchData, doctor, joinId }) => {
  const roleInfo = getRoleInfo(useSelector((state) => state));
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  function onReactivate(userId) {
    setLoading(true);

    const req = {
      user_id: userId,
      doctor_practice_id: joinId,
      variety: doctor ? 1 : 2,
    };

    axios
      .post(`/doctor-user-join/`, req)
      .then(() => {
        toast.success("Added!");
        setShow(false);
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const filteredUsers = show ? users : users.filter((f) => f.status == 1);

  return (
    <React.Fragment>
      {users.filter((f) => f.status == 1).length === 0 && (
        <EmptyStyle>{emptyText}</EmptyStyle>
      )}
      <StyledUserList>
        {filteredUsers
          .sort((a, b) => (a.status > b.status ? 1 : -1))
          .map((u) => (
            <li style={{ opacity: u.status == 2 ? 0.5 : 1 }}>
              <span>
                <Button.Link
                  to={`/users/${u.user_id}`}
                  text={u.full_name}
                  target="_blank"
                />
              </span>
              {u.status == 2 && (
                <Button.Icon
                  icon={<FaPlus />}
                  onClick={() => onReactivate(u.user_id)}
                  loading={loading}
                  disabled={loading}
                />
              )}
              {!roleInfo.admin_research_fellow && u.status == 1 && (
                <UserRemoveButton
                  onSuccess={fetchData}
                  joinId={u.doctor_practice_user_id}
                />
              )}
            </li>
          ))}
      </StyledUserList>
      {/* {users.filter((f) => f.status == 2).length > 0 && (
        <Button.Basic
          text={
            !show
              ? `show disabled ${doctor ? "doctors" : "proxies"}`
              : `hide disabled ${doctor ? "doctors" : "proxies"}`
          }
          onClick={() => setShow(!show)}
          style={{
            fontSize: "15px",
            color: "lightgray",
            border: "none",
          }}
        />
      )} */}
    </React.Fragment>
  );
};

UserList.defaultProps = {
  users: [],
};

const UserRemoveButton = ({ joinId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  if (!joinId) return null;

  function onClick() {
    setLoading(true);

    axios
      .post(`/delete-doctor-user-join/`, {
        doctor_practice_user_id: joinId,
      })
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Removed!");
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <Button.Icon
      icon={<Icon.Remove />}
      onClick={onClick}
      loading={loading}
      disabled={loading}
    />
  );
};

const StyledUserList = styled.ul`
  padding: 0;
  margin: 0.5em 0 0.5em 0;

  li {
    padding: 10px 0;
    display: flex;
    color: #fff;
    font-size: 1.25em;

    & + li {
      border-top: 1px solid #bb80cc;
    }

    &:first-of-type {
      padding-top: 10px;
    }

    span {
      flex-grow: 1;

      a {
        color: #fff;
        /* font-weight: bold; */
      }
    }

    button {
      /* color: var(--primaryColorLight); */
      opacity: 0.25;
    }
  }
`;

const List = styled.div`
  padding: 1.5em 0 0 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 2em;
  }

  & + & {
    /* margin-top: 1em; */
  }
`;

const ListTitle = styled.div`
  button {
    color: #fff;
    padding: 0;
    font-size: 1.15em;

    span {
      margin-right: 4px;
    }
  }
`;

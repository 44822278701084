import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import { BasicTable, Button, Icon, BasicGrid, Card } from "@components/shared";

export default function DoctorProcedure({
  procedureId,
  readOnly,
  doctorId,
  doctorName,
  doctorOccupationalVariety,
  fetchData,
}) {
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    fetchDoctors();
  }, [doctorId]);

  function fetchDoctors() {
    if (doctorId) {
      setDoctors([
        {
          id: doctorId,
          full_name: doctorName,
          occupational_variety_formatted: doctorOccupationalVariety,
        },
      ]);
    } else {
      setDoctors([]);
    }
    // axios
    //   .post(`/retrieve-doctors/`, { procedure_id: procedureId })
    //   .then(({ data }) => {
    //     // setDoctors([]);
    //     setDoctors(data.response[0].data);
    //   })
    //   .catch((err) => {
    //     setDoctors([]);
    //   });
  }

  return (
    <div>
      <BasicGrid columns={2}>
        {doctors.map((d, i) => (
          <Card.Wrapper key={i}>
            <Card.Meta
              title={d.full_name}
              doctor={d.occupational_variety_formatted}
              hasActions={false}
            />
            {/* {!readOnly && (
              <Card.Actions>
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button.Icon
                    icon={
                      <Icon.Remove
                        size={14}
                        color="var(--negativeColorLight)"
                      />
                    }
                    onClick={() => onDoctorRemove(d.id)}
                  />
                </div>
              </Card.Actions>
            )} */}
          </Card.Wrapper>
        ))}
      </BasicGrid>
      {!readOnly && (
        <div style={{ marginTop: "1em" }}>
          <DoctorForm
            disabledIds={doctors.map((m) => m.id)}
            procedureId={procedureId}
            onSuccess={() => fetchData()}
          />
        </div>
      )}
    </div>
  );
}

function DoctorForm({ procedureId, onSuccess, disabledIds }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const userInfo = useSelector((state) => state).user;

  useEffect(() => {
    fetchOptions();
  }, []);

  function fetchOptions() {
    const req = {
      user_id: userInfo ? userInfo.id : null,
      practice_id: userInfo ? userInfo.practice_id : null,
    };

    axios
      .post(`/retrieve-doctors-for-user/`, req)
      .then(({ data }) => {
        setOptions(
          data.response.map((m) => ({
            value: m.doctor_id,
            text: `${m.doctor_name}${
              m.occupational_variety_formatted
                ? ` (${m.occupational_variety_formatted})`
                : ""
            }`,
            disabled: disabledIds.includes(m.doctor_id),
          }))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  function onSubmit() {
    const req = {
      procedure_id: procedureId,
      doctor_id: selectedId,
    };

    setLoading(true);

    axios
      .put(`/procedures/${procedureId}/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        setSelectedId(null);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Form>
        <Form.Select
          placeholder="Select doctor"
          options={options}
          value={selectedId}
          search
          onChange={(e, d) => setSelectedId(d.value)}
        />
        <Button.Basic
          loading={loading}
          text="Add doctor to procedure"
          disabled={!selectedId}
          primary
          type="submit"
          onClick={onSubmit}
        />
      </Form>
    </div>
  );
}

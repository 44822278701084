export const statusOptions = [
  { text: "Active", value: 1 },
  { text: "Inactive", value: 2 },
  { text: "Removed", value: 3 },
  { text: "Invited", value: 4 },
];

export const varietyOptions = [
  { text: "Principal Investigator", value: 1 },
  { text: "Researcher", value: 2 },
  { text: "Iridex Admin", value: 3 },
];
